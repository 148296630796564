<template>
  <p class="clearfix mb-0">
    <span class="float-md-left d-block d-md-inline-block mt-25">
      <b-link
        class="ml-25"
        href="https//ninhbinh.vnpt.vn"
        target="_blank"
      >{{ appBrandName }}</b-link>
      © 2022 - {{ new Date().getFullYear() }}
      <span class="d-none d-sm-inline-block">, All rights Reserved</span>
    </span>

    <span class="float-md-right d-none d-md-block">
      <b-img
          height="35"
          :src="imgSrc"
          alt="logo"
      />
    </span>
  </p>
</template>

<script>
import { BImg, BLink } from 'bootstrap-vue'

export default {
  components: {
    BLink,
    BImg,
  },
  data() {
    return {
      appBrandName: process.env.VUE_APP_BRAND_NAME,
    }
  },
  computed: {
    imgSrc() {
      // eslint-disable-next-line global-require,import/no-dynamic-require
      return require(`@/assets/images/logo/${process.env.VUE_APP_LOGO_FOOTER}`)
    },
  },
}
</script>
