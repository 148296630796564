<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">
      <dark-Toggler class="d-none d-lg-block" />
    </div>

    <template v-if="isLoggedIn">
      <b-navbar-nav class="nav align-items-center ml-auto">
        <b-nav-item>
          <v-select
            v-model="portalProgrammeId"
            label="name"
            :options="user.programmes"
            :reduce="option => option.id"
            :clearable="false"
            :loading="isLoading"
            style="min-width: 230px;"
            @input="onSetProgramme"
          />
        </b-nav-item>
        <notification-dropdown />
        <b-nav-item-dropdown
          right
          toggle-class="d-flex align-items-center dropdown-user-link"
          class="dropdown-user"
        >
          <template #button-content>
            <div class="d-sm-flex d-none user-nav">
              <p class="user-name font-weight-bolder mb-0">
                {{ user ? user.fullName : '' }}
              </p>
            </div>
            <b-avatar
              size="40"
              variant="light-primary"
              badge
              :src="ulrAvatar"
              class="badge-minimal"
              badge-variant="success"
            />
          </template>

          <b-dropdown-item
            link-class="d-flex align-items-center"
            @click="$router.push('profile').catch(err => {})"
          >
            <feather-icon
              size="16"
              icon="UserIcon"
              class="mr-50"
            />
            <span>Thông tin</span>
          </b-dropdown-item>

          <b-dropdown-item
            link-class="d-flex align-items-center"
            @click="showModalResetPassword()"
          >
            <feather-icon
              size="16"
              icon="LockIcon"
              class="mr-50"
            />
            <span>Đổi mật khẩu</span>
          </b-dropdown-item>

          <b-dropdown-divider />

          <b-dropdown-item
            link-class="d-flex align-items-center"
            @click="logout"
          >
            <feather-icon
              size="16"
              icon="LogOutIcon"
              class="mr-50"
            />
            <span>Thoát</span>
          </b-dropdown-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>
    </template>
    <template v-else>
      <b-nav>
        <b-nav-item
          active
          :to="{ name: 'login' }"
        >
          Đăng nhập
        </b-nav-item>
      </b-nav>
    </template>
    <b-modal
      id="changePasswordModal"
      ref="change-password"
      title="Thay đổi mật khẩu"
      :no-close-on-backdrop="!(user && user.changePasswordAt)"
      :no-close-on-esc="!(user && user.changePasswordAt)"
      :hide-header-close="!(user && user.changePasswordAt)"
      :hide-footer="true"
    >
      <ChangePassword @onSucceed="onSucceed" />
    </b-modal>
  </div>
</template>

<script>
import {
  BLink, BNavbarNav, BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar, BNavItem, BNav,
} from 'bootstrap-vue'
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
// eslint-disable-next-line import/extensions
import ChangePassword from '@/views/changePassword/Index'
import { getLoginType, getUser, isUserLoggedIn } from '@/auth/utils'
import vSelect from 'vue-select'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { getMessaging, getToken } from 'firebase/messaging'
import { VAPID_KEY } from '@/const/firebase'
import NotificationDropdown from '@core/layouts/components/app-navbar/components/NotificationDropdown.vue'
import { LOGIN_TYPE, OSType } from '@/const/type'
import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    NotificationDropdown,
    BLink,
    BNav,
    BNavbarNav,
    BNavItem,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,

    // Navbar Components
    DarkToggler,
    vSelect,
    ChangePassword,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      isLoading: false,
      user: getUser(),
      programmeId: null,
      isLoggedIn: isUserLoggedIn(),
      ulrAvatar: '',
    }
  },
  computed: {
    ...mapGetters({
      avatar: 'student/avatar',
    }),
    portalProgrammeId: {
      get() {
        return this.user.portalProgrammeId || this.programmeId
      },
      set(newValue) {
        this.programmeId = newValue
      },
    },
  },
  async created() {
    if (this.isLoggedIn) {
      await this.requestPermission()
      if (!this.user.avatar) {
        // console.log('no avatar')
        // eslint-disable-next-line global-require
        this.ulrAvatar = require('@/assets/images/avatars/default_avatar.jpg')
      } else {
        await this.getAvatarById(this.user.studentId)
        this.ulrAvatar = this.avatar
      }
    }
  },
  mounted() {
    if (this.isLoggedIn) {
      this.checkFirstLogin()
    }
  },
  methods: {
    ...mapActions({
      updatePortalProgramme: 'layout/updatePortalProgramme',
      createAccountFcmToken: 'accountFcmToken/createAccountFcmToken',
      getAvatarById: 'student/getAvatarById',
      verify: 'auth/verify',
    }),
    async requestPermission() {
      const permission = await Notification.requestPermission()
      if (permission === 'granted') {
        const messaging = getMessaging()
        const token = await getToken(messaging, { vapidKey: VAPID_KEY })
        await this.createAccountFcmToken({ code: token, osType: OSType.WEB })
      }
    },
    logout() {
      this.isLoggedIn = false
      const loginType = getLoginType()
      if (loginType === LOGIN_TYPE.NORMAL) {
        useJwt.logoutNormal()
      } else {
        useJwt.logoutSso()
      }
    },
    checkFirstLogin() {
      if (!this.user.changePasswordAt) {
        this.$swal
          .fire({
            title: 'Thông báo!',
            text: 'Vui lòng thay đổi mật khẩu mặc định để bảo vệ thông tin cũng như quyền lợi của mình.!',
            confirmButtonText: '<i class="fa-solid fa-check"></i> Đồng ý',
            cancelButtonText: '<i class="fa-solid fa-times"></i> Hủy',
            customClass: {
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-outline-danger ml-1',
            },
            allowEscapeKey: false,
            allowOutsideClick: false,
            buttonsStyling: false,
          })
          .then(async result => {
            if (result.isConfirmed) {
              this.$bvModal.show('changePasswordModal')
            }
          })
      }
    },
    async onSetProgramme(id) {
      this.isLoading = true
      try {
        const response = await this.updatePortalProgramme({
          studentId: this.user.studentId,
          programmeId: id,
        })
        if (response) {
          const { isSuccessful, message } = response
          if (isSuccessful) {
            this.$router.go(0)
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: message,
                icon: 'XCircleIcon',
                variant: 'danger',
              },
            })
          }
        }
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    showModalResetPassword() {
      this.$bvModal.show('changePasswordModal')
    },

    async onSucceed() {
      const user = await this.verify()
      if (user) {
        this.user = user
        if (this.user.changePasswordAt) {
          this.$router.go(0)
        }
      }
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
