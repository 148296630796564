<template src="./index.html" />

<script>
import {
  BCol, BRow, BFormInput, BFormGroup, BForm, BButton, BModal, BCard, BOverlay,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { getUser } from '@/auth/utils'
import { mapActions } from 'vuex'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@core/utils/validations/validations'

export default {
  name: 'Index',
  components: {
    ValidationObserver,
    ValidationProvider,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BForm,
    BButton,
    BModal,
    BCard,
    BOverlay,
  },
  data() {
    return {
      user: getUser(),
      oldPassword: '',
      newPassword: '',
      reNewPassword: '',
      isLoading: false,
      required,
    }
  },
  methods: {
    ...mapActions({
      changePassword: 'student/changePassword',
    }),
    getElementState(errors) {
      return errors.length > 0 ? false : null
    },
    // handle even
    async handleChangePassword() {
      const valid = this.$refs
        .changePasswordFormRef
        .validate()
      if (valid) {
        this.isLoading = true
        const body = {
          oldPassword: this.oldPassword,
          newPassword: this.newPassword,
        }
        try {
          const res = await this.changePassword({ id: this.user.accountId, body })
          if (res.isSuccessful) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Thay đổi mật khẩu thành công',
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
            this.$bvModal.hide('changePasswordModal')
            this.$emit('onSucceed')
          }
        } catch (e) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `[${e.code}] ${e.message}`,
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        } finally {
          this.isLoading = false
        }
      }
    },
  },
}
</script>
